export const LYKA_PHONE_NUMBER = '1300 851 936'
export const LYKA_SUPPORT_EMAIL = 'info@lyka.com.au'
export const LYKA_TWITTER_HANDLE = 'lykapetfood'
export const LYKA_SUPPORT_HOURS_WEEKDAY: Readonly<[string, string]> = ['8:00', '18:00']
export const LYKA_SUPPORT_HOURS_WEEKEND: Readonly<[string, string]> = ['10:00', '18:00']
export const LYKA_TIMEZONE = 'Australia/Sydney' as const
export const LYKA_SUPPORT_HOURS_TIMEZONE = 'AEST' as const

export const LYKA_FACEBOOK_URL = 'https://www.facebook.com/lykapetfood/'
export const LYKA_INSTAGRAM_URL = 'https://www.instagram.com/lykapetfood/'
export const LYKA_TIKTOK_URL = 'https://www.tiktok.com/@lykapetfood'
export const LYKA_LINKEDIN_URL = 'https://www.linkedin.com/company/lykapetfood'

export const LYKA_BAB_PATH = '/get-started'
export const LYKA_LOGIN_PATH = '/account/login'

export const DEFAULT_COUPON_CODE = 'FRESH30'
export const DEFAULT_COUPON_DISCOUNT = 30

export const TRUSTPILOT_RATING = 4.9
export const TRUSTPILOT_REVIEW_COUNT = 3_000
export const ANIMALS_COUNT = 110_000
export const LYKA_TRUSTPILOT_URL = 'https://au.trustpilot.com/review/lyka.com.au'
